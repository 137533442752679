"use strict";
import _merge from 'lodash/merge';
import {removeClass, addClass, hasClass, findAncestorByClass} from "./../helpers";
import Vue from 'vue';
import EventListDay from './EventListDay';
import config from './config';
import slugify from 'slugify';

import axios from 'axios';

import moment from 'moment';
import de from 'moment/locale/de'
import {sortEvents, sortEventsForDate} from "../common/sort-events";
moment.locale('de');


require('smoothscroll-polyfill').polyfill();


Vue.config.productionTip = false;


const defaults = {
  selectors: {
      eventListContainer: '.js-event-list-day',
      eventListDataContainer: '.js-event-list-day-data',
      eventListMetaDataContainer: '.js-event-list-day-meta',
  },
  classes: {
      activeClass: 'is-active',
      initClass: 'is-event-list-initialized',
  },
    imageBasePath: config.imageBasePath,
    defaultTeaserImage : config.defaultTeaserImage,
    eventsBasePath: config.eventsBasePath,
    api: config.api,

    // initialState: {
    //     day: false,
    //     category: false,
    // }
};


const state = {

};

class AmEventListDay {
    constructor(eventListContainer, eventListData, eventListOptions) {
        this.eventListContainer = eventListContainer;
        this.options = eventListOptions;

        // if (!eventListData) {
        this.needsToQueryData = !eventListData;
        this.hasData = !!eventListData;
        // }

        this.eventListItems = {};
        this.eventListMeta = null;
        this.initialState = null;
        this.startDate = this.options.eventListDate;

        if (this.needsToQueryData) {

            this.queryEventData(this.startDate)
                .then(data => this.transformEventsData(data))
                .then(data => this.buildMetaData(data))
                .then(data => this.findInitialState(data))
                .then(data => this.applyInitalState(data))
                .then(data => {
                    console.log(data);
                    this.initEventList(eventListContainer)
                })
        } else {
            this.transformEventsData(eventListData)
                .then(data => this.buildMetaData(data))
                .then(data => this.findInitialState(data))
                .then(data => this.applyInitalState(data))
                .then(data => {
                    console.log(data);
                    this.initEventList(eventListContainer)
                })
        }

        // this.eventListItems = this.transformEventsData(eventListData);
        //
        // this.eventListMeta = this.buildMetaData(eventListData);
        //
        // this.initialState = this.findInitialState();
        //
        // this.eventListMeta.initialState = this.initialState;


        // this.initEventList(eventListContainer);


    }

    buildDateSlug(date) {
        let slug = moment(date).format('YYYY-MM-DD');
        // debugger;
        return slug;
    }

    queryEventData(day) {
        return new Promise(resolve => {

            // let parts = day.split('-');
            // parts = parts.map(part => {
            //     return this.pad(part);
            // });
            // let dayString = parts.join('-');

            let query = this.options.api.path + '/date/' + day;
            axios.get(query, {
                params: {
                    // search: day,
                    // searchFields: 'starts_at',
                    // // with: 'places;tags;images',
                    // status: 'published',

                }
            })
                .then(function (response) {
                    console.log(response);
                    let dataSet = {};
                    dataSet[day] = response.data.data;
                    console.log(dataSet);
                    resolve(dataSet);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });

    }

    transformEventsData(eventListData) {
        return new Promise(resolve => {
            let eventListItems = eventListData[this.startDate].map(item => {

                // item.teaserImage = item.images.length > 0 ? item.images[0].path : this.options.defaultTeaserImage;

                // item.startTime = moment(item.starts_at).getTime();

                item.startDate = moment(item.schedules[0].starts_at);

                item.link = item.slug ? this.options.eventsBasePath + '/' + item.slug : false;

                return item;
            });

            eventListItems =  sortEvents(eventListItems);

            this.eventListItems[this.startDate] = eventListItems;

            // if(eventListItems.length)
            resolve(eventListItems);
        });


    }

    buildMetaData(eventListItems) {
        return new Promise(resolve => {
            let eventListMeta = {
                eventListIdentifier: this.options.eventListIdentifier,
                // allDays: getallDays(eventListItems),
                // allCategories: getallCategories(eventListItems),
            };

            // eventListMeta.firstDay = eventListMeta.allDays.length > 0 ? eventListMeta.allDays[0].day : this.options.eventListMetaData.date;
            eventListMeta.firstDay = this.options.eventListMetaData.date;
            // eventListMeta.startCategory = '*';
            eventListMeta.itemsToShow = parseInt(this.options.eventListCount);

            eventListMeta.dayNav = {
                left_arrow : this.options.eventListMetaData.left_arrow,
                right_arrow : this.options.eventListMetaData.right_arrow,
                link : this.options.eventListMetaData.link,
                link_label : this.options.eventListMetaData.link_label,
                date : this.options.eventListMetaData.date,
            };

            this.eventListMeta = eventListMeta;

            console.log(eventListMeta);

            resolve(eventListMeta);
        });
    }

    findInitialState(eventListMeta) {
        return new Promise(resolve => {

            let initialState = {};
            initialState.day = this.eventListMeta.firstDay;
            // initialState.day = eventListMeta.allDays.find(element => {
            //     console.log(element);
            //     return element.day === this.eventListMeta.firstDay;
            // });

            // initialState.category = eventListMeta.allCategories.find(element => {
            //     return slugify(element, {lower: true }) === this.options.initialState.category
            //     // return element.day === this.options.initialState.day;
            // });

            this.initialState = initialState;
            resolve(initialState);
        });
    }

    applyInitalState(initalState) {
        return new Promise(resolve => {
            this.eventListMeta.initialState = initalState;

            this.hasData = true;

            resolve(this.hasData)
        })
    }

    // pad(number) {
    //     if (number < 10) {
    //         return '0' + number;
    //     }
    //     return number;
    // }

    initEventList(eventListContainer) {
        if(!this.eventListItems) {
            return false;
        }

        if(!this.eventListMeta) {
            return false;
        }

        new Vue({
            el: eventListContainer,
            data: {
                eventListContainer: this.eventListContainer,
                eventListItems: this.eventListItems,
                eventListMeta: this.eventListMeta,
            },
            components: { EventListDay },
            template: '<EventListDay/>'
        });

        addClass(this.eventListContainer, this.options.classes.initClass);

    }



}

// function getallDays(eventListItems) {
//     let days = [];
//     let tempDays = [];
//     eventListItems.forEach(item => {
//         let itemDate = moment(item.starts_at);
//         let dayContainer = {
//             day : itemDate.date(),
//             month: itemDate.month(),
//             year: itemDate.year(),
//             isoWeekDay: itemDate.day(),
//             date: itemDate,
//         };
//
//
//         let dayIdent = dayContainer.month + '-' +  dayContainer.day + '-' + dayContainer.isoWeekDay;
//
//         if(tempDays.indexOf(dayIdent) === -1) {
//             tempDays.push(dayIdent);
//             days.push(dayContainer);
//         }
//     });
//
//     days.sort(function (a, b) {
//         return a.date - b.date;
//     });
//
//     return days;
// }
//
// function getallCategories(eventListItems) {
//     let categories = [];
//     eventListItems.forEach(item => {
//         let category = JSON.parse(JSON.stringify(item.category));
//
//         if(categories.indexOf(category) === -1) {
//             categories.push(category);
//         }
//     });
//     return categories;
// }

export function initEventListDay (options) {

    let eventListOptions = _merge(defaults, options);

    let eventListContainers = document.querySelectorAll(eventListOptions.selectors.eventListContainer);

    // let hashparts = window.location.hash.substr(1).split('-!-');


    if (eventListContainers) {
        Array.from(eventListContainers).forEach(eventListContainer => {

            let eventListIdentifier = eventListContainer.dataset.identifier;
            let eventListCount = eventListContainer.dataset.count;
            let eventListDate = eventListContainer.dataset.date;

            let eventListDataContainer = eventListContainer.querySelector(eventListOptions.selectors.eventListDataContainer);

            let eventListData =  eventListDataContainer ? JSON.parse(eventListDataContainer.text) : false;

            let eventListMetaDataContainer = eventListContainer.querySelector(eventListOptions.selectors.eventListMetaDataContainer);

            let eventListMetaData =  eventListMetaDataContainer ? JSON.parse(eventListMetaDataContainer.text)[0] : false;

            if (eventListMetaData) {
                eventListOptions.eventListIdentifier = eventListMetaData.identifier;
                eventListOptions.eventListCount = eventListMetaData.count;
                eventListOptions.eventListDate = eventListMetaData.date;

                eventListOptions.eventListMetaData = eventListMetaData;

            } else {
                eventListOptions.eventListIdentifier = eventListIdentifier;
                eventListOptions.eventListCount = eventListCount;
                eventListOptions.eventListDate = eventListDate;
            }

            if(eventListData) {

                new AmEventListDay(eventListContainer, eventListData, eventListOptions);

                // if (hashparts[0] === eventListIdentifier ) {
                //
                //     let ancestor = findAncestorByClass(eventListContainer, 'content-block');
                //
                //     setTimeout(function () {
                //         ancestor.scrollIntoView({ behavior: 'smooth' });
                //     }, 1000);
                //
                //
                //     eventListOptions.initialState.day = parseInt(hashparts[1]);
                //     eventListOptions.initialState.category = hashparts[2];
                // }

            } else {
                new AmEventListDay(eventListContainer, null, eventListOptions);

            }
        });
    }

}

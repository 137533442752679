"use strict";
import config from './config';
import axios from "axios/index";

import {matchIconToTagId} from "./map-icons";

export function queryMapData(params = {}, getListings = false) {

    return new Promise(resolve => {

        if (getListings) {
            // get the listing data to the places
            params['with'] = "listing";
        }
        axios.get(config.api.path_places, {
            params: params
                // {
                // search: '3',
                // searchFields: 'package_id',
                // with: 'places;tags;images',
                // status: 'published',

            // }
        })
            .then(function (response) {
                console.log(response);
                resolve(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    });
}

export function cleanUpMapData(mapData) {
    return new Promise(resolve => {
        console.log(mapData);
        // mapData = mapData.filter(item => {
        //     return item.package_id >= config.package_id_min;
        // });
        mapData = mapData.sort((a,b) => {
            let nameA = a.display_name ? a.display_name.toUpperCase() : a.name.toUpperCase(); // ignore upper and lowercase
            let nameB = b.display_name ? b.display_name.toUpperCase() : b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });

        resolve(mapData)
    })
}

export function buildGeoJsonFromMapData(data) {
    let geoJson = {
        type: "FeatureCollection",
        features: [],
    };

    let tempFeatures = [];

    data.forEach(place => {
        let newFeature = {
            type: "Feature",
            properties: {
                icon: 'star',
                title: place.display_name || place.name,
                placesDescription: place.description,
                // listing_id: place.listing_id,
                // listing: place.listing,
                place_id: place.id,
                // category_fallback_image: listing.category_fallback_image,
                // website: listing.website,

                address: {
                    street: place.street,
                    street_number: place.street_number || '',
                    street_number_additional: place.street_number_additional || '',
                    postal_code: place.postal_code,
                    city: place.city,
                },

                phone_numbers: place.phone_numbers || [],

            },
            geometry: place.geometry

        };

        if(place.listing.status === 2) {
            newFeature.properties.listing_id =  place.listing_id;
            newFeature.properties.listing =  place.listing;
            newFeature.properties.link = place.listing.slug || '';

        }

        // @TODO: use tags from places
        // if (listing.tags && listing.tags.length > 0) {
        //     newFeature.properties.tags = listing.tags.filter(listingTag => {
        //         if(listingTag.tag_group_id === config.tagGroupId) {
        //             return {
        //                 id: listingTag.id,
        //                 tag_group_id: listingTag.tag_group_id,
        //                 name: listingTag.display_name ? listingTag.display_name : listingTag.name
        //             }
        //         }
        //     });
        //
        //     newFeature.properties.icon = matchIconToTagId(newFeature.properties.tags[0].id);
        //     newFeature.properties.filterTag = newFeature.properties.tags[0].id;
        // } else {
        //     newFeature.properties.tags = [];
        // }

        // @TODO: use tags from places
        if (place.tags && place.tags.length > 0) {
            newFeature.properties.tags = place.tags.filter(placeTag => {
                if(placeTag.tag_group_id === config.tagGroupId) {
                    return {
                        id: placeTag.id,
                        tag_group_id: placeTag.tag_group_id,
                        name: placeTag.display_name ? placeTag.display_name : placeTag.name
                    }
                }
            });

            if(newFeature.properties.tags.length > 0) {
                newFeature.properties.icon = matchIconToTagId(newFeature.properties.tags[0].id);
                newFeature.properties.filterTag = newFeature.properties.tags[0].id;
            }
        } else {
            newFeature.properties.tags = [];
        }

        // if(listing.images && listing.images.length > 0 && listing.images[0].id) {
        //     newFeature.properties.image = {
        //         id: listing.images[0].id,
        //         path: listing.images[0].path,
        //     }
        // } else {
            newFeature.properties.image = {
                id: null,
                path: config.defaultImage,
            };
        // }
        tempFeatures.push(newFeature);
    });


    geoJson.features = tempFeatures;

    geoJson = JSON.parse(JSON.stringify(geoJson));

    return geoJson;
}

export function groupFeaturesByTagGroup(data, tagGroupId) {
    return data.reduce(function (acc, obj) {
        obj.properties.tags.forEach(tag => {
            let key = null;
            if(tag.tag_group_id === tagGroupId) {
                key = tag.id;
            }
            if (key) {
                if (!acc[key]) {
                    acc[key] = {
                        id: tag.id,
                        name: tag.display_name || tag.name,
                        features: []
                    };
                }
                acc[key].features.push(obj);
            }
        });
        return acc;
    }, {});
}

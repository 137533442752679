var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.iconInner
    ? _c("span", {
        staticClass: "icon",
        class: _vm.classes,
        domProps: { innerHTML: _vm._s(_vm.iconInner) },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
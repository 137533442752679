"use strict";
import _merge from 'lodash/merge';
import _groupBy from 'lodash/groupBy';
import {removeClass, addClass, hasClass, findAncestorByClass} from "./helpers";
import axios from 'axios';

import getImageSize from './image-sizes';
import {matchIconToTagId} from "./common/maps/map-icons";

import * as listingsMapData from "./common/maps/map-data-listings.js"
import * as placesMapData from "./common/maps/map-data-places.js"
// import {getIcon} from "./common/get-icon";

import {loadIcon} from "./common/load-icon";

// const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
const MapboxLanguage = require('@mapbox/mapbox-gl-language');

const config = {
    mapbox: {
        accessToken: 'pk.eyJ1IjoiYXVnc2J1cmdtYXJrZXRpbmciLCJhIjoiY2p4dnkwcHRsMDlhMzNtazllamdiNDZuaSJ9.z4gNTJO929v_7onMoRXMew',
        // style: 'mapbox://styles/visionbites/cjhc0e1b30m742rmvu710hypc',
        style: 'mapbox://styles/mapbox/light-v9',
    },

};


const defaults = {
  selectors: {
      mapContainer: '.js-map-container',
      mapOuter: '.js-map-outer',
      mapHead: '.js-map-head',
      map: '.js-map',
      mapList: '.js-map-list',
      mapLegend: '.js-map-legend',
      mapLegendToggle: '.js-map-legend-toggle',
      mapDataContainer: '.js-map-data',
  },
  classes: {
      activeClass: 'is-active',
      initClass: 'is-map-initialized',
      legendOpenClass: 'is-legend-open'
  },
    api: {
        path_map: '/api/listings/map',
        path_places: 'api/places'
    },
    defaultImage: 'img/placeholders/main-icon.png',
    tagGroupId: 37,
    defaultZoom: 15,
    defaultZoomSingle: 17,
    package_id_min: 1,
    defaultCenter: {
        lng: 10.898844389713418,
        lat: 48.36839370505203
    },
};


class AmMap {
    constructor(mapContainer, mapData, mapOptions) {
        this.mapContainer = mapContainer;
        this.options = mapOptions;
        this.dataSrc = mapContainer.dataset.src;
        this.showAddressInPopup = mapContainer.dataset.popupShowAddress;
        this.imageBasePath = mapContainer.dataset.imageBasePath;
        this.shouldFitBounds = mapContainer.dataset.fitBounds === "true";
        this.mapType = mapContainer.dataset.mapType || 'listings';
        this.mapQuery = mapContainer.dataset.mapQuery;
        this.mapQueryValue = mapContainer.dataset.mapQueryValue;

        this.mapEl = this.mapContainer.querySelector(this.options.selectors.map);
        this.mapOuter = findAncestorByClass(mapContainer, this.options.selectors.mapOuter);
        this.mapList = this.mapContainer.querySelector(this.options.selectors.mapList);

        this.mapLegend = this.mapOuter.querySelector(this.options.selectors.mapLegend);
        this.mapLegendToggle = this.mapOuter.querySelector(this.options.selectors.mapLegendToggle);
        this.mapHead = this.mapOuter.querySelector(this.options.selectors.mapHead);
        this.mapLegendButtons = [];

        this.zoomFactor = this.options.defaultZoom;
        this.updateZoomClass();


        this.listItems = [];
        this.markers = [];
        this.mapData = {};

        this.popup = null;

        if (!mapData) {
            if(this.mapType === "places") {

                const queryParams = {};
                if(this.mapQuery && this.mapQueryValue) {
                    queryParams[this.mapQuery] = this.mapQueryValue;
                }

                placesMapData.queryMapData(queryParams, true)
                    .then(data => placesMapData.cleanUpMapData(data))
                    .then(data => this.updateMapData(data))
                    .then(data => this.buildMap(data));

            } else {
                listingsMapData.queryMapData()
                    .then(data => listingsMapData.cleanUpMapData(data))
                    .then(data => this.updateMapData(data))
                    .then(data => this.buildMap(data));
            }
            // this.queryMapData()
            //     .then(data => this.cleanUpMapData(data))
            //     .then(data => this.updateMapData(data))
            //     .then(data => this.buildMap(data));
        } else {
            let tempData = [];
            tempData.push(mapData);

            this.updateMapData(tempData)
                .then(data => this.buildMap(data))
        }

        this.bindMapLegend();
        this.mapContainer.classList.add(this.options.classes.initClass);
    }

    // cleanUpMapData(mapData) {
    //     return new Promise(resolve => {
    //         mapData = mapData.filter(item => {
    //             return item.package_id >= this.options.package_id_min;
    //         });
    //         mapData = mapData.sort((a,b) => {
    //             let nameA = a.display_name ? a.display_name.toUpperCase() : a.name.toUpperCase(); // ignore upper and lowercase
    //             let nameB = b.display_name ? b.display_name.toUpperCase() : b.name.toUpperCase(); // ignore upper and lowercase
    //             if (nameA < nameB) {
    //                 return -1;
    //             }
    //             if (nameA > nameB) {
    //                 return 1;
    //             }
    //
    //             // names must be equal
    //             return 0;
    //         });
    //
    //         resolve(mapData)
    //     })
    // }

    updateMapData(mapData) {
        return new Promise(resolve => {
            if (this.mapType === "places") {
                this.mapData = placesMapData.buildGeoJsonFromMapData(mapData);
            } else {
                this.mapData = listingsMapData.buildGeoJsonFromMapData(mapData);
            }
            if(this.mapLegend) {
                this.mapLegendData = this.buildMapLegend(mapData);
            }
            resolve(this.mapData);
        });
    }

    buildMapLegend (data) {
        this.groupedData = listingsMapData.groupFeaturesByTagGroup(data, this.options.tagGroupId);

        Object.values(this.groupedData).forEach(featureCollection => {

            let iconMarkup = loadIcon("markers/" + matchIconToTagId(featureCollection.id));

            let el = document.createElement('div');
            el.className = 'maphead-button';
            addClass(el, 'maphead-button--category');
            addClass(el, 'js-maphead-button');
            addClass(el, 'tag-' + matchIconToTagId(featureCollection.id));
            el.dataset.targetId = featureCollection.id;

            el.innerHTML = iconMarkup;
            el.innerHTML += `<span class="maphead-button-text">${featureCollection.name}</span>`;

            this.mapLegendButtons.push(el);

            this.mapLegend.appendChild(el);

            el.addEventListener('click', (e) => {

                if (hasClass(el, 'is-active')) {
                    removeClass(el, 'is-active');
                    this.markers.forEach(marker => {
                        removeClass(marker, 'u-hide');
                    });
                    this.listItems.forEach(listItem => {
                        removeClass(listItem, 'u-hide');
                    });

                    delete this.mapContainer.dataset.filterId;

                } else {
                    this.mapLegendButtons.forEach(button => {

                        if(button === el) {
                            addClass(button, 'is-active')
                        } else {
                            removeClass(button, 'is-active')
                        }
                    });

                    this.filterMarkers(el.dataset.targetId);
                    this.filterList(el.dataset.targetId);

                    this.mapContainer.dataset.filterId = el.dataset.targetId;

                }
                e.stopPropagation();
                e.preventDefault();
            });
        })
    }

    filterMarkers(tagId) {

        let grouped = _groupBy(this.markers,(item => {
            return hasClass(item, 'has-tag-' + tagId)
        }));

        grouped['false'].forEach(marker => {
            addClass(marker, 'u-hide');
       });

        grouped['true'].forEach(marker => {
            removeClass(marker, 'u-hide');
        });
    }

    filterList(tagId) {

        let grouped = _groupBy(this.listItems,(item => {
            return hasClass(item, 'has-tag-' + tagId)
        }));

        grouped['false'].forEach(listItem => {
            addClass(listItem, 'u-hide');
       });

        grouped['true'].forEach(listItem => {
            removeClass(listItem, 'u-hide');
        });
    }

    // groupFeaturesByTagGroup(data, tagGroupId) {
    //     return data.reduce(function (acc, obj) {
    //         obj.tags.forEach(tag => {
    //             let key = null;
    //             if(tag.tag_group_id === tagGroupId) {
    //                 key = tag.id;
    //             }
    //             if (key) {
    //                 if (!acc[key]) {
    //                     acc[key] = {
    //                         id: tag.id,
    //                         name: tag.display_name || tag.name,
    //                         features: []
    //                     };
    //                 }
    //                 acc[key].features.push(obj);
    //             }
    //         });
    //
    //         // debugger;
    //         return acc;
    //     }, {});
    // }

    // buildGeoJson(data) {
    //     let geoJson = {
    //         type: "FeatureCollection",
    //         features: [],
    //     };
    //
    //     let tempFeatures = [];
    //
    //     data.forEach(listing => {
    //         listing.places.forEach(place => {
    //             let newFeature = {
    //                 type: "Feature",
    //                 properties: {
    //                     icon: 'star',
    //                     title: listing.display_name || listing.name || place.display_name|| place.name,
    //                     shortDescription: listing.headline,
    //                     placesDescription: place.description,
    //                     description: listing.description,
    //                     package_id: listing.package_id,
    //                     listing_id: listing.id,
    //                     place_id: place.id,
    //                     link: listing.slug || '',
    //
    //                     address: {
    //                         street: place.street,
    //                         street_number: place.street_number || '',
    //                         street_number_additional: place.street_number_additional || '',
    //                         postal_code: place.postal_code,
    //                         city: place.city,
    //                     },
    //
    //
    //
    //                 },
    //                 geometry: place.geometry
    //
    //             };
    //
    //             // if (listing.tags && listing.tags.length > 0) {
    //             //     newFeature.properties.tags = listing.tags.filter(listingTag => {
    //             //         if(listingTag.tag_group_id === this.options.tagGroupId) {
    //             //             return {
    //             //                 id: listingTag.id,
    //             //                 tag_group_id: listingTag.tag_group_id,
    //             //                 name: listingTag.display_name ? listingTag.display_name : listingTag.name
    //             //             }
    //             //         }
    //             //     })
    //             // }
    //
    //             if (place.tags && place.tags.length > 0) {
    //                 newFeature.properties.tags = place.tags.filter(placeTag => {
    //                     if(placeTag.tag_group_id === this.options.tagGroupId) {
    //                         return {
    //                             id: placeTag.id,
    //                             tag_group_id: placeTag.tag_group_id,
    //                             name: placeTag.display_name ? placeTag.display_name : placeTag.name
    //                         }
    //                     }
    //                 })
    //             }
    //             if(listing.images && listing.images.length > 0 && listing.images[0].id) {
    //                 newFeature.properties.image = {
    //                     id: listing.images[0].id,
    //                     path: listing.images[0].path,
    //                 }
    //             } else {
    //                 newFeature.properties.image = {
    //                     id: null,
    //                     path: this.options.defaultImage,
    //                 }
    //             }
    //             tempFeatures.push(newFeature);
    //         })
    //     });
    //
    //     geoJson.features = tempFeatures;
    //
    //     geoJson = JSON.parse(JSON.stringify(geoJson));
    //
    //     return geoJson;
    // }

    buildMap(data) {
        mapboxgl.accessToken = config.mapbox.accessToken;

        this.map = new mapboxgl.Map({
            container: this.mapEl, // container id
            style: config.mapbox.style, // stylesheet location
            center: [this.options.defaultCenter.lng, this.options.defaultCenter.lat], // starting position [lng, lat]
            zoom: this.options.defaultZoom, // starting zoom
            cooperativeGestures: true
        });

        let that = this;

        this.map.on('load', (e) => {
            this.map.addSource('places', {
                type: 'geojson',
                data: this.mapData
            });


            this.createPopupNode();
            that.addControls();

            if(this.mapList) {
                this.buildLocationList(data);
            }
            this.addMarkers(data);
        });

        this.map.on('zoom', (e) => {
            this.zoomFactor = this.map.getZoom();
            this.updateZoomClass(this.zoomFactor);
        })
    }

    updateZoomClass(zoomfactor) {
        zoomfactor = zoomfactor || this.zoomFactor;
        this.mapContainer.dataset.zoomFactor = parseInt(zoomfactor);
    }

    addMarkers(data) {
        let bounds = new mapboxgl.LngLatBounds();

        data.features.forEach((marker, i) => {

            // create a HTML element for each feature
            let el = document.createElement('div');
            el.className = 'marker';
            el.classList.add('marker-' + marker.properties['marker-symbol']);
            el.dataset.id = marker.properties.place_id;
            el.dataset.listingId = marker.properties.listing_id;


            if(marker.properties.tags) {
                marker.properties.tags.forEach(tag => {
                    addClass(el, 'has-tag-' + tag.id);
                });
            }

            this.markers.push(el);

            if(!marker.geometry) {
                // @TODO: is that possible?
            }

            if(this.shouldFitBounds) {
                bounds.extend(marker.geometry.coordinates);
            }

            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el, { offset: [0, -23] })
                .setLngLat(marker.geometry.coordinates)
                .addTo(this.map);

            el.addEventListener('click', (e) => {
                let activeItem = document.getElementsByClassName('active');
                // 1. Fly to the point
                this.flyToLocation(marker);
                // 2. Close all other popups and display popup for clicked store
                this.createPopUp(marker);
                // 3. Highlight listing in sidebar (and remove highlight for all other listings)
                e.stopPropagation();
                if (activeItem[0]) {
                    activeItem[0].classList.remove('active');
                }

                if(this.mapList) {
                    var listing = document.getElementById('listing-' + i);
                    listing.classList.add('active');
                }
            });

            // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-hover/
            el.addEventListener('mouseenter', e => {
                // Change the cursor style as a UI indicator.
                this.map.getCanvas().style.cursor = 'pointer';

                let coordinates = marker.geometry.coordinates.slice();
                let description = marker.properties.title;

                // Populate the popup and set its coordinates
                // based on the feature found.
                this.popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
            });

            el.addEventListener('mouseleave', () => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove();
            });
        });

        if(this.shouldFitBounds) {
            if (data.features.length > 1) {
                this.map.fitBounds(bounds, { padding: {top: 100, bottom:100, left: 100, right: 100} });
            } else {
                this.map.setCenter(data.features[0].geometry.coordinates);
                this.map.setZoom(this.options.defaultZoomSingle);
            }
        }
    }

    createPopupNode() {
        this.popup = new mapboxgl.Popup({
            offset: [0, -32],
            closeButton: false,
            closeOnClick: false
        });
    }

    buildLocationList(data) {
        // Iterate through the list of stores
        for (let i = 0; i < data.features.length; i++) {
            let currentFeature = data.features[i];
            // Shorten data.feature.properties to just `prop` so we're not
            // writing this long form over and over again.
            let prop = currentFeature.properties;
            // Select the listing container in the HTML and append a div
            // with the class 'item' for each store

            let listing = this.mapList.appendChild(document.createElement('a'));

            listing.className = 'maplist-item';
            listing.id = 'listing-' + i;
            listing.href = '#';
            listing.dataPosition = i;

            if(currentFeature.properties.tags) {
                currentFeature.properties.tags.forEach(tag => {
                    addClass(listing, 'has-tag-' + tag.id);
                });
            }

            // let imagePath = getImageSize(this.imageBasePath + '/' + prop.image.path, "150x150");
            let imagePath = this.imageBasePath + '/' + prop.image.path + '?width=150&height=150&progressive=1';

            this.listItems.push(listing);

            listing.innerHTML = `
                    <img class="maplist-image" src="${imagePath}" width="150" height="150">
                    <span class="maplist-content">
                      <span class="maplist-title">${prop.title}</span>
                      <span class="maplist-text">${prop.address.street} ${prop.address.street_number}, ${prop.address.postal_code} ${prop.address.city} </span>
                    </span>`;


            // Add an event listener for the links in the sidebar listing
            listing.addEventListener('click', (e) => {
                e.preventDefault();
                // Update the currentFeature to the store associated with the clicked link
                let clickedListing = data.features[listing.dataPosition];
                // 1. Fly to the point associated with the clicked link
                this.flyToLocation(clickedListing);
                // 2. Close all other popups and display popup for clicked store
                this.createPopUp(clickedListing);
                // 3. Highlight listing in sidebar (and remove highlight for all other listings)
                let activeItem = document.getElementsByClassName('active');
                if (activeItem[0]) {
                    activeItem[0].classList.remove('active');
                }
                e.target.parentNode.classList.add('active');
            });
        }
    }


    // queryMapData() {
    //
    //     return new Promise(resolve => {
    //
    //         axios.get(this.options.api.path_map, {
    //             params: {
    //                 // search: '3',
    //                 // searchFields: 'package_id',
    //                 // with: 'places;tags;images',
    //                 // status: 'published',
    //
    //             }
    //         })
    //             .then(function (response) {
    //                 console.log(response);
    //                 resolve(response.data.data);
    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             });
    //     });
    // }

    // }

    addControls() {
        this.map.addControl(new mapboxgl.NavigationControl());

        this.map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }), 'top-right');

        this.map.addControl(new MapboxLanguage({
            defaultLanguage: 'de'
        }));
    }

    bindMapLegend() {

        this.mapLegend = this.mapOuter.querySelector(this.options.selectors.mapLegend);
        this.mapLegendToggle = this.mapOuter.querySelector(this.options.selectors.mapLegendToggle);
        this.mapHead = this.mapOuter.querySelector(this.options.selectors.mapHead);

        if (!this.mapLegendToggle) {
            return false;
        }

        this.mapLegendToggle.addEventListener('click', e => {
            e.preventDefault();

            if(hasClass(this.mapHead, this.options.classes.legendOpenClass)) {
                removeClass(this.mapHead, this.options.classes.legendOpenClass)
            } else {
                addClass(this.mapHead, this.options.classes.legendOpenClass)
            }
        })

    }

    flyToLocation(currentFeature) {
        const zoomZoom = this.zoomFactor > this.options.defaultZoomSingle ? this.zoomFactor : this.options.defaultZoomSingle;
        this.map.flyTo({
            center: currentFeature.geometry.coordinates,
            zoom: zoomZoom
        });
    }

    createPopUp(currentFeature) {
        var popUps = document.getElementsByClassName('mapboxgl-popup');
        // Check if there is already a popup on the map and if so, remove it
        if (popUps[0]) popUps[0].remove();

        let popupHtml = '<h3 class="popup-headline">' + currentFeature.properties.title + '</h3>';

        if (currentFeature.properties['shortDescription']) {
            popupHtml = popupHtml +  '<div class="popup-content content"><p>' + currentFeature.properties.shortDescription + '</p></div>';
        }

        if (this.showAddressInPopup) {
            popupHtml = popupHtml +
                '<div class="popup-content content">' +
                currentFeature.properties['address']['street'] + ' ' + currentFeature.properties['address']['street_number'] + '<br>' +
                currentFeature.properties['address']['postal_code'] + ' ' + currentFeature.properties['address']['city'] + '<br>' +
                '</div>';
        }
        if (currentFeature.properties['link'] ) {
            popupHtml = popupHtml + '<div class="popup-content content"><a class="popup-link btn-secondary" href="/listings/' + currentFeature.properties['link'] + '">mehr anzeigen</a></div>';
        } else if (currentFeature.properties['link-text'] && currentFeature.properties['link-url']) {
            popupHtml = popupHtml + '<div class="popup-content content"><a class="popup-link btn-secondary" href="' + currentFeature.properties['link-url'] + '">' + currentFeature.properties['link-text'] +'</a></div>';
        }

        var popup = new mapboxgl.Popup({ closeOnClick: false, offset: [0, -32] })
            .setLngLat(currentFeature.geometry.coordinates)
            .setHTML(popupHtml)
            .setMaxWidth('none')
            .addTo(this.map);
    }
}

export function initMaps (options) {

    let mapOptions = _merge(defaults, options);
    let mapContainers = document.querySelectorAll(defaults.selectors.mapContainer);

    if (mapContainers) {
        Array.from(mapContainers).forEach(mapContainer => {
            let mapDataContainer = mapContainer.querySelector(mapOptions.selectors.mapDataContainer);
            let mapData = mapDataContainer ? (mapDataContainer.text.trim().length > 0 ? JSON.parse(mapDataContainer.text) : false) : false;
            // let mapData = mapDataContainer ? (mapDataContainer.text.trim().length > 0 ? JSON.parse(mapDataContainer.text) : false) : false;



            if(mapData) {
                new AmMap(mapContainer, mapData, mapOptions);
            } else {
                new AmMap(mapContainer, null, mapOptions);

            }
        });
    }
}

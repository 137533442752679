export function matchIconToTagId(tagId) {
    let icon =  null;
    tagId = parseInt(tagId);
    switch (tagId) {
        case 2449:
            icon = 'townhall';
            break;
        case 2450:
            icon = 'culture';
            break;
        case 2451:
            icon = 'family';
            break;
        case 2452:
            icon = 'food';
            break;
        case 2453:
            icon = 'dress';
            break;
        case 2549:
            icon = 'parking';
            break;
        default:
            icon = 'default';
            break;
    }

    return icon;
}

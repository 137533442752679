"use strict";
import _merge from 'lodash/merge';
import {removeClass, addClass, hasClass, findAncestorByClass} from "./helpers";

// const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

const defaults = {
    selectors: {
        mapContainer: '.js-map-container-embed',
        mapOuter: '.js-map-outer',
        mapHead: '.js-map-head',
        map: '.js-map',
        mapList: '.js-map-list',
        mapLegend: '.js-map-legend',
        mapLegendToggle: '.js-map-legend-toggle',
    },
    classes: {
        activeClass: 'is-active',
        initClass: 'is-map-initialized',
        legendOpenClass: 'is-legend-open'
    },
    tagGroupId: 37,
    defaultZoom: 15,
    defaultZoomSingle: 17,
    package_id_min: 1,
    defaultCenter: {
        lng: 10.898844389713418,
        lat: 48.36839370505203
    },
};

class AmEmbedMap {
    constructor(mapContainer, options) {
        this.mapContainer = mapContainer;
        this.options = _merge(defaults, options);
        this.dataSrc = mapContainer.dataset.src;

        this.mapEl = this.mapContainer.querySelector(this.options.selectors.map);
        this.mapOuter = findAncestorByClass(mapContainer, this.options.selectors.mapOuter);

        this.zoomFactor = this.options.mapbox.zoom || this.options.defaultZoom;
        this.updateZoomClass();

        this.buildMap();
        this.bindMapLegend();

        this.mapContainer.classList.add(this.options.classes.initClass);

    }

    buildMap() {
        mapboxgl.accessToken = this.options.mapbox.accessToken;

        this.map = new mapboxgl.Map({
            container: this.mapEl, // container id
            style: this.options.mapbox.style, // stylesheet location
            center: this.options.mapbox.center, // starting position [lng, lat]
            zoom: this.options.mapbox.zoom, // starting zoom
            cooperativeGestures: true

        });

        let that = this;

        this.map.on('load', (e) => {
            that.addControls();
            that.bindMap();
        });

        this.map.on('zoom', (e) => {
            this.zoomFactor = this.map.getZoom();
            this.updateZoomClass(this.zoomFactor);
        })
    }

    updateZoomClass(zoomfactor) {
        zoomfactor = zoomfactor || this.zoomFactor;
        this.mapContainer.dataset.zoomFactor = parseInt(zoomfactor);
    }

    addControls() {
        this.map.addControl(new mapboxgl.NavigationControl());

        this.map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }), 'top-right');
    }

    bindMap() {

        this.map.on('click', (e) => {
            let features = this.map.queryRenderedFeatures(e.point, {
                layers: [this.options.mapbox.dataLayer] // replace this with the name of the layer
            });

            if (!features.length) {
                return;
            }

            let feature = features[0];


            if(feature.properties['noOverlay'] === 'true') {
                return false;
            }

            if(feature.geometry['type'] !== 'Point') {
                return false;
            }

            this.createPopUp(feature);
            this.flyToLocation(feature);
        });
    }

    bindMapLegend() {

        this.mapLegend = this.mapOuter.querySelector(this.options.selectors.mapLegend);
        this.mapLegendToggle = this.mapOuter.querySelector(this.options.selectors.mapLegendToggle);
        this.mapHead = this.mapOuter.querySelector(this.options.selectors.mapHead);

        if(!this.mapLegend || !this.mapLegendToggle || !this.mapHead) {
            return false;
        }

        this.mapLegendToggle.addEventListener('click', e => {
            e.preventDefault();

            if(hasClass(this.mapHead, this.options.classes.legendOpenClass)) {
                removeClass(this.mapHead, this.options.classes.legendOpenClass)
            } else {
                addClass(this.mapHead, this.options.classes.legendOpenClass)
            }
        })

    }

    flyToLocation(currentFeature) {
        const zoomZoom = this.zoomFactor > this.options.defaultZoomSingle ? this.zoomFactor : this.options.defaultZoomSingle;
        this.map.flyTo({
            center: currentFeature.geometry.coordinates,
            zoom: zoomZoom
        });
    }

    createPopUp(currentFeature) {
        var popUps = document.getElementsByClassName('mapboxgl-popup');
        // Check if there is already a popup on the map and if so, remove it
        if (popUps[0]) popUps[0].remove();

        console.log(currentFeature);

        let popupHtml = '<h3 class="popup-headline">' + currentFeature.properties.title + '</h3>';

        if (currentFeature.properties['shortDescription']) {
            popupHtml = popupHtml +  '<div class="popup-content content"><p>' + currentFeature.properties.shortDescription + '</p></div>';
        }

        if (currentFeature.properties['link-text'] && currentFeature.properties['link-url']) {
            popupHtml = popupHtml + '<div class="popup-content content"><a class="popup-link btn-secondary" href="' + currentFeature.properties['link-url'] + '">' + currentFeature.properties['link-text'] +'</a></div>';
        }

        let popup = new mapboxgl.Popup({ closeOnClick: false })
            .setLngLat(currentFeature.geometry.coordinates)
            .setHTML(popupHtml)
            .setMaxWidth('none')
            .addTo(this.map);
    }
}

export function initEmbedMaps () {

    let mapContainers = document.querySelectorAll(defaults.selectors.mapContainer);

    if (mapContainers) {
        Array.from(mapContainers).forEach(mapContainer => {
            let options = {
                mapbox: {
                    accessToken: mapContainer.dataset.mapAccessToken,
                    style: mapContainer.dataset.mapStyle,
                    center: mapContainer.dataset.mapCenter.split(','),
                    zoom: parseInt(mapContainer.dataset.mapZoom),
                    dataLayer: mapContainer.dataset.mapDatalayer,
                }
            };
            new AmEmbedMap(mapContainer, options);
        });
    }

}

const config = {
    imageBasePath: 'https://static.augsburg-city.de/',
    defaultTeaserImage: 'img/placeholders/event-default-header.jpg',
    defaultListingImage: 'img/placeholders/listing_cats/kategorie_sonstiges-in-augsburg.jpg',
    listingsBasePath: '/listings',
    placesToShow: 4,
    // api: {
    //     // path: '/api/places/discover',
    //     path: '/api/listings/map'
    // },
    mapbox: {
        accessToken: 'pk.eyJ1IjoidmlzaW9uYml0ZXMiLCJhIjoiY2pyMG1seXd2MG1xdzN4cGNhODllY25wbiJ9.9PGLSrAAnDlZ69aL4BLDig',
        // style: 'mapbox://styles/visionbites/cjhc0e1b30m742rmvu710hypc',
        style: 'mapbox://styles/visionbites/cjr0miqj53f302snv2lmd4ver',
    },

    api: {
        path_listings_map: '/api/listings/map',
        path_places: '/api/places'
    },
    defaultImage: 'img/placeholders/main-icon.png',
    tagGroupId: 37,
    defaultZoom: 15,
    defaultZoomSingle: 17,
    package_id_min: 1,
    defaultCenter: {
        lng: 10.898844389713418,
        lat: 48.36839370505203
    }


};


export default config;


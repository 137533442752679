"use strict";


const defaults = {
    sizes: [
        "150x150",
        "640x640",
        "1200x640",
        "1200x800",
        "1472x640",
    ]
};

export default function getImageSize (imagePath, size) {
    let targetSize = defaults.sizes.includes(size) ? size : false;

    if(!targetSize) {
        console.warn('invalid size requested for ' + imagePath);
        return imagePath;
    }

    let pathParts = imagePath.split('/');

    let currentSize = (defaults.sizes.indexOf(pathParts[pathParts.length - 2]) !== -1) ? pathParts[pathParts.length - 2] : false;

    // if the requested size is the current size
    if (targetSize === currentSize) {
        return imagePath;
    }

    if(currentSize) {
        pathParts[pathParts.length - 2] = targetSize;
    } else {
        pathParts.splice([pathParts.length - 1], 0, targetSize);
    }

    return pathParts.join('/');
}

<template>
    <span class="icon" :class="classes" v-if="iconInner" v-html="iconInner"></span>
</template>

<script>
    import {loadIcon} from "./load-icon";

    export default {
        name: 'icon',
        props: {
            src: {
                type: String,
                required: true
            },
            classes: {
                type: String
            }
        },

        data() {
            return {
                iconInner: '',
            }
        },

        methods: {
            loadIcon() {
                loadIcon(this.src)
                    .then(svgCode => {
                        this.iconInner = svgCode
                    });
            }
        },

        mounted() {
            this.loadIcon();
        },

        updated() {
            this.loadIcon();
        },

        watch: {
            src() {
                this.loadIcon();
            }
        }
    };
</script>

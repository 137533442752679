import axios from 'axios';

const base_path =  "/assets/img/";

export function loadIcon(src) {

    return new Promise( resolve => {

        initCache();

        if(hasCache(src)) {
            resolve(getCache(src))
        } else {
            resolve(loadFile(src));
        }
    })
}

function loadFile(src) {
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: base_path + src,
        })
            .then(response => {
                let svgCode = response.data;
                setCache(src, svgCode);
                resolve(svgCode);
            })
    })
}
function setCache(src, value) {
    if (!value) {
        window.icon_cache.has(src);
        clearCache(src);
        return;
    }

    window.icon_cache.set(src, value);
    return src;
};

function getCache(src) {
    if (!window.icon_cache.has(src)) {
        return;
    }
    if(window.icon_cache.get(src) === "pending") {
        return "pending";
    }

    if(window.icon_cache.get(src) !== "pending") {
        return window.icon_cache.get(src)
    }
};

function hasCache(src) {
    if (!window.icon_cache.has(src)) {
        return false;
    }
    return true;
}

function clearCache(src) {
    window.icon_cache.delete(src);
};

function initCache() {
    if(!window.icon_cache) {
        window.icon_cache = new Map();
    }
}

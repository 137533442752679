const config = {
    // imageBasePath: 'https://media2.augsburg-city.de/',
    imageBasePath: 'https://augsburg-marketing-places.ddev.site/',
    defaultTeaserImage: 'img/placeholders/event-default-header.jpg',
    eventsBasePath: '/events',
    schedulesToShow: 2,
    api: {
        path: '/api/events'
    },
    // mapbox: {
    //   accessToken: 'pk.eyJ1IjoidmlzaW9uYml0ZXMiLCJhIjoiY2poYWt4MHo3MDg1bjMwbzduajRsNWFkeiJ9.rxn6muwb0yDnSEmE7ER51w',
    //   style: 'mapbox://styles/visionbites/cjhc0e1b30m742rmvu710hypc',
    // },

    filters: {
        "group": {
            "sorting": ["tour_day"],
            "groups": {
                "tour_day": "Datum"
            },
            "tags": {
                "tour_day": [{
                    "id": "2022-05-05",
                    "tag_group_id": "tour_day",
                    "name": "2022-05-05",
                    "display_name": "05.05.2022"
                }, {
                    "id": "2022-05-06",
                    "tag_group_id": "tour_day",
                    "name": "2022-05-06",
                    "display_name": "06.05.2022"
                }, {
                    "id": "2022-05-07",
                    "tag_group_id": "tour_day",
                    "name": "2022-05-07",
                    "display_name": "07.05.2022"
                }, {
                    "id": "2022-05-08",
                    "tag_group_id": "tour_day",
                    "name": "2022-05-08",
                    "display_name": "08.05.2022"
                }]
            }
        },
        "single": [{
            "id": "has_workshop",
            "name": "has_workshop",
            "display_name": "Zum Mitmachen"
            // }, {
            //     "id": "has_limit",
            //     "name": "has_limit",
            //     "display_name": "Limitierte Führung"
        }, {
            "id": "wheelchair_accessible",
            "name": "wheelchair_accessible",
            "display_name": "Barrierefrei"
        }
        ]
    },
};


export default config;

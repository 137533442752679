"use strict";

import moment from "moment/moment";

export function sortEvents (events) {

    let exhibitions = events.filter(item => item.category === "Ausstellung");
    let others = events.filter(item => item.category !== "Ausstellung");

    exhibitions.sort((a, b) => {
        return a.startDate - b.startDate
    });
    others.sort((a, b) => {
        return a.startDate - b.startDate
    });

    return others.concat(exhibitions);
}

export function sortEventsForDate (events, date) {
    let compareDate = moment(date);
    // let eventsAtDate = events.filter(event => {
    //     return event.startDate.toDateString() === date.toDateString();
    // });

    let exhibitions = events.filter(item => item.category === "Ausstellung");
    let others = events.filter(item => item.category !== "Ausstellung");

    exhibitions.sort((a, b) => {
        let relevantDateA = a.schedules.find(schedule => compareDate.isSame(schedule.startDate, 'day'));
        let relevantDateB = b.schedules.find(schedule => compareDate.isSame(schedule.startDate, 'day'));

        return relevantDateA.startDate - relevantDateB.startDate
    });
    others.sort((a, b) => {
        let relevantDateA = a.schedules.find(schedule => compareDate.isSame(schedule.startDate, 'day'));
        let relevantDateB = b.schedules.find(schedule => compareDate.isSame(schedule.startDate, 'day'));

        return relevantDateA.startDate - relevantDateB.startDate
    });

    return others.concat(exhibitions);

}

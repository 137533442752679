<template>
    <div class="row justify-content-between am-event-list-day event-list-day js-event-list">

        <div class="col-xl-3 col-lg-4">
            <div class="event-list-day-date">
                <div class="event-list-day-date-day u-text-uppercase u-color-red">{{ moment(currentDate).format('dddd') }}</div>
                <div class="event-list-day-date-number">{{ moment(currentDate).format('D') }}</div>
                <div class="event-list-day-date-month u-text-uppercase">{{ moment(currentDate).format('MMMM') }}</div>
                <div class="event-list-day-date-arrows">
                    <button class="event-list-day-date-next" @click="goToNextDay">
                        <span class="icn" v-html="dayNav.right_arrow"></span>
                    </button>
                    <button class="event-list-day-date-prev" @click="goToPrevDay" v-if="currentDate > nowDate">
                        <span class="icn" v-html="dayNav.left_arrow"></span>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-lg-1 u-hide--d ">

        </div>

        <div class="col-lg-8">
            <div class="event-list-day-list">
                <div class="event-list-day-item" v-for="item in filteredItems" >

                    <div class="event-info-container">

                        <time class="event-list-day-time" :datetime="moment(item.startDate).toISOString()">{{  moment(item.startDate).format('HH:mm') }} Uhr</time>
                        <a class="event-title-link" :href="item.link" v-if="item.link" @click.prevent="handleLink(item.link)">
                            <h3 v-html="item.name" class="event-list-day-title"></h3>
                        </a>
                        <h3 v-html="item.name" class="event-list-day-title" v-else></h3>
                        <div class="event-list-day-location " v-if="item.place">
                            <!--<div class="icon-col" v-html="icon('markers/marker-blue-outline')"></div>-->
                            <icon src="markers/marker-blue-outline.svg" classes="icon-col"> </icon>

                            <div class="text-col">
                                {{ item.place.name }} <br>
                                <!--{{ item.place.street }} {{ item.place.street_number }}<br>-->
                                <!--{{ item.place.postal_code }} {{ item.place.city }}-->
                            </div>
                        </div>

                    </div>
                </div>
                <div class="event-list-event u-align-center" v-if="filteredItems.length === 0">
                    Leider keine Ergebnisse
                </div>
            </div>
            <a class="btn-secondary" :href="dayNav.link">{{ dayNav.link_label }}</a>

        </div>



    </div>
</template>

<script>
    import config from './config';
    import axios from 'axios';
    import slugify from 'slugify';
    import moment from 'moment';
    import de from 'moment/locale/de'
    moment.locale('de');

    import icon from "./../common/icon";
    import {sortEvents} from "../common/sort-events";

    export default {
        name: 'EventList',
        components: {
            icon
            // BaseMarker
        },
        data() {
            return {
                config: config,
                eventListContainer: this.$root._data.eventListContainer,
                eventListItems: this.$root._data.eventListItems,
                eventListMeta: this.$root._data.eventListMeta,
                filters: {
                    day: false,
                    // category: false,
                },
                changed: false,
                cutOffAtChar: 300,
                dayNav: this.$root._data.eventListMeta.dayNav,
                currentDate: moment(this.$root._data.eventListMeta.dayNav.date),
                nowDate: moment(),
            }
        },
        methods: {
            moment(...args) {
                return moment(...args);
            },
            // mapMonthName(monthIndex) {
            //     const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"
            //     ];
            //     return monthNames[monthIndex];
            // },
            // mapWeekDay(weekDay) {
            //     const dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", ];
            //     return dayNames[weekDay];
            // },
            // mapIsoWeekDay(isoWeekDay) {
            //     const dayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
            //     return dayNames[isoWeekDay - 1];
            // },
            // excerpt(fullText) {
            //     return fullText.length < this.cutOffAtChar ?
            //         fullText :
            //         fullText.substring(0, this.cutOffAtChar) + ' […]';
            // },
            // formatTime(tstamp) {
            //     let options = {
            //         hour: 'numeric', minute: 'numeric',
            //     };
            //     return new Intl.DateTimeFormat('de-DE', options).format(tstamp)
            // },
            // icon (iconname) {
            //     return require('html-loader!./../../../../dev/img/' + iconname + '.svg');
            // },
            handleLink(link) {
                // this.pushSlugToHistory();
                window.location.href = link;
            },
            // pushSlugToHistory () {
            //     history.pushState(this.stateSlug, "", "#" + this.stateSlug);
            // },
            goToNextDay() {
                let newDate = moment(this.currentDate).add(1, 'days');

                this.goToDay(newDate);
            },
            goToPrevDay() {

                let newDate = moment(this.currentDate).add(-1, 'days');
                // let newDate = this.currentDate.addDays(-1);
                this.goToDay(newDate);
            },

            goToDay(newDate) {
                // return moment(this.nowDate).isSameOrBefore(newDate, 'day');

                if(moment(newDate).isBefore(this.nowDate, 'day')) {
                    console.log('stop living in the past');
                    return false;
                }

                let newDateSlug = this.buildDateSlug(newDate);

                if(this.eventListItems[newDateSlug]) {
                    console.log("from cache");
                    this.switchData(newDateSlug);
                } else {
                    this.queryEventData(newDateSlug)
                        .then(data => this.transformEventsData(data))
                        .then(dateSlug => this.switchData(dateSlug))
                        .then(response => console.log(response));
                }

            },
            switchData(dateSlug) {

                return new Promise(resolve => {
                    // let dateSlug = Object.keys(dataSet)[0];

                    this.currentDate = moment(dateSlug);
                    this.filters.day = this.buildDateSlug(this.currentDate);
                    resolve(true);
                });
            },
            pad(number) {
                if (number < 10) {
                    return '0' + number;
                }
                return number;
            },
            buildDateSlug(date) {
                let slug = moment(date).format('YYYY-MM-DD');
                // debugger;
                return slug;
            },
            queryEventData(dateSlug) {
                return new Promise(resolve => {

                    // let parts = dateSlug.split('-');
                    // parts = parts.map(part => {
                    //     return this.pad(part);
                    // });
                    // let dayString = parts.join('-');

                    // let queryDate = this.buildDateSlug(date);
                    let query = this.config.api.path + '/date/' + dateSlug;

                    axios.get(query, {
                        // params: {
                        //     search: dateSlug,
                        //     searchFields: 'starts_at',
                        //     // with: 'places;tags;images',
                        //     status: 'published',
                        //
                        // }
                    })
                        .then(function (response) {
                            console.log(response);

                            let dataSet = {};
                            dataSet[dateSlug] = response.data.data;
                            console.log(dataSet);
                            resolve(dataSet);

                            // resolve(response.data.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                });

            },

            transformEventsData(dataSet) {
                return new Promise(resolve => {
                    let dateSlug = Object.keys(dataSet)[0];

                    let eventListItems = dataSet[dateSlug].map(item => {

                        // item.teaserImage = item.images.length > 0 ? item.images[0].path : this.options.defaultTeaserImage;

                        // item.startTime = new Date(item.starts_at).getTime();

                        item.startDate = moment(item.schedules[0].starts_at);

                        item.link = item.slug ? this.config.eventsBasePath + '/' + item.slug : false;

                        return item;
                    });

                    eventListItems = sortEvents(eventListItems);

                    dataSet[dateSlug] = eventListItems;
                    this.eventListItems[dateSlug] = eventListItems;
                    resolve(dateSlug);
                });


            }
        },
        mounted() {
            // this.extendDateInfo();

            this.filters.day = this.eventListMeta.firstDay;
            // this.filters.category = this.filters.category || this.eventListMeta.startCategory;

            // if(this.eventListMeta.initialState.day) {
            //     this.filters.day = this.eventListMeta.initialState.day.day;
            // }

            // if(this.eventListMeta.initialState.category) {
            //     this.filters.category = this.eventListMeta.initialState.category;
            // }

            // this.currentDate.setHours(0,0,0,0);
            // this.nowDate.setHours(2,0,0,0);
        },
        computed: {

            currentDateSlug() {
                return this.buildDateSlug(this.currentDate)
            },

            filteredItems() {

                let dayFilter = this.filters.day;
                // let categoryFilter = this.filters.category ;

                let limit = this.eventListMeta.itemsToShow;

                console.log(this.currentDate);

                let fItems = this.eventListItems[this.currentDateSlug];

                console.log(fItems);

                if (!fItems) {
                    return []
                }

                // if(categoryFilter !== '*') {
                //     fItems = fItems.filter(item => {
                //         return item.category === categoryFilter;
                //     });
                // }

                fItems = fItems.filter(item => {
                    let dateSlug = this.buildDateSlug(moment(item.schedules[0].starts_at));
                    return dateSlug === dayFilter;
                });

                fItems = fItems.slice(0, limit);

                return fItems;
            },
            // stateSlug() {
            //
            //     if(!this.filters.day || !this.filters.category) {
            //         return false;
            //     }
            //
            //     if (!this.changed) {
            //
            //         if(!this.eventListMeta.initialState.day && !this.eventListMeta.initialState.category) {
            //             if(this.filters.day === this.eventListMeta.firstDay && this.filters.category === this.eventListMeta.startCategory) {
            //                 console.warn("no bueno");
            //                 return false
            //             }
            //         }
            //     }
            //
            //     this.changed = true;
            //
            //     return this.eventListMeta.eventListIdentifier + '-!-' + this.filters.day + '-!-' + slugify(this.filters.category, {lower: true })
            // }
        }

    }
</script>

import Flickity from 'flickity-imagesloaded';
import {addClass} from "./helpers";


const defaults = {
    selectors: {
        homeTeaserSlideshow: '.js-home-teaser-slider',
        homeTeaserSlide: '.js-home-teaser-slide',
    },
    classes: {
        initClass: 'flky-init'
    },
    autoplaySpeed: 5000,
};

const state = {
    slideshows: []
};



function initialize () {

    let homeTeaserSlideshows = document.querySelectorAll(defaults.selectors.homeTeaserSlideshow);

    if (!homeTeaserSlideshows) {
        return false;
    }

    if (homeTeaserSlideshows) {
        Array.from(homeTeaserSlideshows).forEach(homeTeaserSlideshow => {

            console.log(homeTeaserSlideshow);

            if(homeTeaserSlideshow.querySelectorAll(defaults.selectors.homeTeaserSlide).length <= 1) {
                return false;
            }

            let autoplay = homeTeaserSlideshow.dataset['autoplay'] ? defaults.autoplaySpeed : false;

            let singleHomeTeaserSlideshow = new Flickity( homeTeaserSlideshow, {
                arrowShape: 'M77.4039775,90.8332078 C79.4639281,92.8616076 79.5398336,96.2285954 77.5735171,98.3535857 C75.6072007,100.478576 72.3432663,100.556878 70.2833158,98.5284782 L21,50 L70.2833158,1.47152181 C72.3432663,-0.556877955 75.6072007,-0.478575912 77.5735171,1.64641432 C79.5398336,3.77140455 79.4639281,7.1383924 77.4039775,9.16679217 L35.9356284,50 L77.4039775,90.8332078 Z',
                cellSelector: defaults.selectors.homeTeaserSlide,
                wrapAround: true,
                cellAlign: 'left',
                autoPlay: autoplay,
                imagesLoaded: true,
                // adaptiveHeight: true,
            });

            addClass(homeTeaserSlideshow, defaults.classes.initClass);
            state.slideshows.push(singleHomeTeaserSlideshow)
        });
    }

}


export function initHomeTeaserSlideshows() {

    console.log("slideshows");
    initialize();
}


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "row justify-content-between am-event-list-day event-list-day js-event-list",
    },
    [
      _c("div", { staticClass: "col-xl-3 col-lg-4" }, [
        _c("div", { staticClass: "event-list-day-date" }, [
          _c(
            "div",
            {
              staticClass:
                "event-list-day-date-day u-text-uppercase u-color-red",
            },
            [_vm._v(_vm._s(_vm.moment(_vm.currentDate).format("dddd")))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "event-list-day-date-number" }, [
            _vm._v(_vm._s(_vm.moment(_vm.currentDate).format("D"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "event-list-day-date-month u-text-uppercase" },
            [_vm._v(_vm._s(_vm.moment(_vm.currentDate).format("MMMM")))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "event-list-day-date-arrows" }, [
            _c(
              "button",
              {
                staticClass: "event-list-day-date-next",
                on: { click: _vm.goToNextDay },
              },
              [
                _c("span", {
                  staticClass: "icn",
                  domProps: { innerHTML: _vm._s(_vm.dayNav.right_arrow) },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.currentDate > _vm.nowDate
              ? _c(
                  "button",
                  {
                    staticClass: "event-list-day-date-prev",
                    on: { click: _vm.goToPrevDay },
                  },
                  [
                    _c("span", {
                      staticClass: "icn",
                      domProps: { innerHTML: _vm._s(_vm.dayNav.left_arrow) },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-1 u-hide--d" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-8" }, [
        _c(
          "div",
          { staticClass: "event-list-day-list" },
          [
            _vm._l(_vm.filteredItems, function (item) {
              return _c("div", { staticClass: "event-list-day-item" }, [
                _c("div", { staticClass: "event-info-container" }, [
                  _c(
                    "time",
                    {
                      staticClass: "event-list-day-time",
                      attrs: {
                        datetime: _vm.moment(item.startDate).toISOString(),
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.moment(item.startDate).format("HH:mm")) +
                          " Uhr"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  item.link
                    ? _c(
                        "a",
                        {
                          staticClass: "event-title-link",
                          attrs: { href: item.link },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleLink(item.link)
                            },
                          },
                        },
                        [
                          _c("h3", {
                            staticClass: "event-list-day-title",
                            domProps: { innerHTML: _vm._s(item.name) },
                          }),
                        ]
                      )
                    : _c("h3", {
                        staticClass: "event-list-day-title",
                        domProps: { innerHTML: _vm._s(item.name) },
                      }),
                  _vm._v(" "),
                  item.place
                    ? _c(
                        "div",
                        { staticClass: "event-list-day-location" },
                        [
                          _c("icon", {
                            attrs: {
                              src: "markers/marker-blue-outline.svg",
                              classes: "icon-col",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-col" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.place.name) +
                                " "
                            ),
                            _c("br"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm.filteredItems.length === 0
              ? _c("div", { staticClass: "event-list-event u-align-center" }, [
                  _vm._v(
                    "\n                Leider keine Ergebnisse\n            "
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "btn-secondary", attrs: { href: _vm.dayNav.link } },
          [_vm._v(_vm._s(_vm.dayNav.link_label))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
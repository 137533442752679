import docReady from 'doc-ready';

import initGlobal from './global.js';

import { lang } from './components/helpers.js'

import {initMaps} from './components/maps';
import {initHomeTeaserSlideshows} from "./components/home-teaser-slider";
import {initEventListDay} from "./components/events-list/event-list-day";
import {initEmbedMaps} from "./components/embed-maps";


function initHomePage() {

    console.log('Language from homepage: ' + lang);

    window.initMaps = initMaps;
    window.initEmbedMaps = initEmbedMaps;

    initHomeTeaserSlideshows();
    initEventListDay();
}

docReady(function () {
    initGlobal();
    initHomePage();
});
